<template>
  <div class="c-verify-status">
    <img class="warning__icon" src="@/assets/icons/confirm.svg" v-if="status"/>
    <re-button round="round" type="border" @click="handleClick" :disabled="disabled" :style="{ width: 'auto', padding: '0 16px' }" v-else>
      {{label}} <slot />
    </re-button>

  </div>
</template>
<script>

import ReButton from '@/components/form/ReButton.vue';

export default {
  name: 'verify-status',
  components: {
    ReButton
  },
  props: {
    status: {
      type: [Boolean, String, null],
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    }
  }
};
</script>
<style lang="scss" scoped>
.c-verify-status {
  display: inline-block;
  margin-left: 10px;
}
</style>
