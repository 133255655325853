const useLockScroll = (name = 'body') => ({
        lock(n = name) {
            const el = document.querySelector(n);
            console.log(el);
            el.classList.add('lock');
        },
        unlock(n = name) {
            const el = document.querySelector(n);
            el.classList.remove('lock');
        }
    });
export default useLockScroll;
