<template>
  <section id="member_data_info">
    <div class="container w1300">
      <div class="fliter_outer">
        <MenuClassificationFilter :menuLists="menuLists" />
      </div>
      <div class="info_outer">
        <div class="btn-wrap">
          <!-- // 客戶要求不要顯示同步資料的按鈕 -->
          <!-- <re-button round="round" type="border" class="reload" :style="{ width: '140px' }" @click="syncData">
            <p class="reload__text">{{ $t("profile.sync") }}</p>
            <img class="reload__icon" src="@/assets/icons/reload.svg" />
          </re-button> -->
        </div>
        <ul class="member_info" v-if="memberProfile">
          <li class="info_item">
            <p class="info-wrap">
              <span class="t txt-medium">{{ $t("name") }}</span>
              <span>{{ memberProfile.name }}</span>
            </p>
            <p class="info-wrap txt-theme">
              <span class="t txt-medium">{{ $t("patientNo") }}</span>
              <span>{{ memberProfile.nuwa_patient_no }}</span>
            </p>
          </li>

          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("gender") }}</span>
              <span>{{ sex }} </span>
            </div>
          </li>

          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("register.nation") }}</span>
              <span>{{ nationality }} </span>
            </div>
          </li>

          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("uidOrPassportNum") }}</span>
              <span>{{ memberProfile.account }} </span>
            </div>
          </li>
          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("register.timeZone") }}</span>
              <span>{{ timezone }} </span>
            </div>
          </li>

          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("phoneNumber") }}</span>
              <span>{{ `${memberProfile.country_code} ${memberProfile.phone}` }} </span>
              <verify-status :status="memberProfile.phone_verified_at" :label="$t('sendSms')"
                @click="handleVerifyPhone">
              </verify-status>
            </div>
          </li>
          <li class="info_item">
            <p class="info-wrap">
              <span class="t txt-medium">{{ $t("address") }}</span>
              <span>{{ memberProfile.addr }}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="info-wrap">
              <span class="t txt-medium">{{ $t("birthDay") }}</span>
              <span>{{ memberProfile.birthday }}</span>
            </p>
          </li>
          <li class="info_item">
            <div class="info-wrap info-wrap--email">
              <span class="t txt-medium">{{ $t("form.email") }}</span>

              <div class="info-wrap__detail">
                <span class="info-wrap__detail__email">{{ memberProfile.email }}</span>

                <verify-status :status="memberProfile.email_verified_at" :label="$t('sendEmail')"
                  @click="handleVerifyEmail">
                </verify-status>

                <!-- <verify-status :status="memberProfile.email_verified_at" @click="handleVerifyEmail" :disabled="disabled"
                  py>
                  <Timer ref="emailTimerRef" :api="triggerSendEmail" :btnName="$t('sendEmail')"
                    timerKey="sendEmailBtnTimer" @setDisabledStatus="setDisabledStatus" />
                </verify-status> -->
              </div>
            </div>
          </li>

          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("privacyPolicy.title") }}</span>
              <div class="info-wrap__detail">
                <verify-status :status="memberProfile.agree_privicy" :disabled="memberProfile.agree_privicy"
                  @click="handlerPrivacyPopup(true)" :title="memberProfile.agree_privicy ? $t('agree') : ''">{{
                    $t('privacyPolicy.confirm')
                  }}</verify-status>
              </div>
            </div>
          </li>
          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("emergencyName") }}</span>
              <span>{{ memberProfile.emergency_name || '-' }} </span>
            </div>
          </li>
          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("emergencyRelative") }}</span>
              <span>{{ memberProfile.emergency_relative || '-' }} </span>
            </div>
          </li>
          <li class="info_item">
            <div class="info-wrap">
              <span class="t txt-medium">{{ $t("emergencyTel") }}</span>
              <span>{{ memberProfile.emergency_tel || '-' }} </span>
            </div>
          </li>

          <li class="info_item">
            <div class="info-wrap">
              <re-button round="round" type="border" @click="openDialog('modifyPassword')"
                :style="{ minWidth: '120px' }">
                {{ $t("modifyPassword") }}
              </re-button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <re-dialog :visible="visible.privacyPopup" footerPosition="center" :template="Privacy"
      @beforeClose="handlerPrivacyPopup(false)" />

    <re-dialog :visible.sync="visible.modifyPassword" footerPosition="center" @beforeClose="closeDialog"
      :template="ModifyPassword" />

    <re-dialog :visible.sync="visible.msg" footerPosition="center" :data="msgInfo" @beforeClose="closeDialog('msg')"
      :template="Msg" />
  </section>
</template>

<script>
import '@/assets/scss/member.scss';
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';
import { syncMemberData, verifyEmail, nationalityList, timeZoneList } from '@/lib/cakeHttp';
import VerifyStatus from '@/components/member/VerifyStatus.vue';
import ReButton from '@/components/form/ReButton.vue';
import { getApiLangCode } from '@/utils/getLangCode';
import { mapMutations, mapState } from 'vuex';
import ModifyPassword from '@/components/dialog/content/ModifyPassword.vue';
import Msg from '@/components/dialog/content/Msg.vue';
import ReDialog from '@/components/dialog/ReDialog.vue';
// import Timer from '@/components/Timer.vue';
import Privacy from '@/components/dialog/content/Privacy.vue';
import useLockScroll from '@/utils/useLockScroll';

export default {
  name: 'MemberDatainfo',
  components: {
    MenuClassificationFilter,
    VerifyStatus,
    ReButton,
    ReDialog,
    // Timer
  },
  props: {
    Classification: Array,
    memberProfile: null,
    isTaiwanId: Boolean,
    getThisMemberProfileFn: Function
  },
  data() {
    return {
      ModifyPassword,
      Msg,
      disabled: false,
      visible: {
        modifyPassword: false,
        msg: false,
        privacyPopup: false
      },
      msgInfo: {
        desc: this.$t('modifySuccess'),
        success: true
      },
      timeZoneMapping: {},
      lockScroll: useLockScroll(),
      Privacy
    };
  },
  computed: {
    ...mapState(['cacheNationality']),
    menuLists() {
      return [
        {
          label: '',
          lists: this.Classification.map((item, uuid) => ({ ...item, title: item.linkName, uuid }))
        }
      ];
    },
    nationality() {
      if (!this.cacheNationality) {
        return this.memberProfile.country;
      }

      return (
        this.cacheNationality?.[this.memberProfile.country]?.[
        getApiLangCode()
        ] || ''
      );
    },
    sex() {
      switch (this.memberProfile.sex) {
        case 'm':
          return this.$t('male');
        case 'f':
          return this.$t('female');
        default:
          return '-';
      }
    },
    timezone() {
      if (Object.keys(this.timeZoneMapping).length === 0) {
        return '';
      }

      const lang = getApiLangCode();
      return this.timeZoneMapping?.[this.memberProfile.timezone]?.[lang] || '';
    }
  },
  methods: {
    ...mapMutations(['toggleShowMsg', 'setMsgInfo', 'setCacheNationality']),
    async fetchNationality() {
      if (this.cacheNationality) {
        return;
      }

      nationalityList({}, ({ data }) => {
        this.setCacheNationality(data);
      });
    },

    setDisabledStatus(status) {
      this.disabled = status;
    },
    closeDialog(type, extraInfo) {
      this.visible[type] = false;

      if (extraInfo) {
        this.openDialog(extraInfo.actionType);

        if (extraInfo.action === 'succeed') {
          this.msgInfo = {
            desc: this.$t('modifySuccess'),
            succeed: true
          };
        } else {
          this.msgInfo = {
            desc: extraInfo.err,
            succeed: false
          };
        }
      }
    },
    openDialog(type) {
      this.visible[type] = true;
    },
    handleVerifyPhone() {
      this.$router.push({
        name: 'Validate'
      });

      // verifyPhone(
      //   {
      //     // lang_id: getApiLangCode(),
      //     // type: 'web'
      //   },
      //   (data) => {
      //     console.log('verifyPhone...data', data);
      //     this.toggleShowMsg(true);
      //     this.setMsgInfo({
      //       succeed: false,
      //       desc: '驗證信已發送'
      //     });
      //   },
      //   (err) => {
      //     console.log('verifyPhone...err', err);
      //     this.toggleShowMsg(true);
      //     this.setMsgInfo({
      //       succeed: false,
      //       desc: err
      //     });
      //   }
      // );
    },
    handleVerifyEmail() {
      // this.$refs.emailTimerRef.handleClick();
      this.$router.push({
        name: 'ValidateEmail'
      });
    },
    // handleVerifyEmail() {
    //   this.$refs.emailTimerRef.handleClick();
    // },
    triggerSendEmail() {
      verifyEmail(
        {
          lang_id: getApiLangCode(),
          type: 'web'
        },
        () => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: this.$t('emailWasSent')
          });
        },
        (err) => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: err
          });
        }
      );
    },
    syncData() {
      syncMemberData((_, status) => {
        if (status) {
          this.getThisMemberProfileFn();
        }
      });
    },
    fetchTimezoneData() {
      timeZoneList({}, ({ data }) => {
        console.log('data', data);
        // const lang = getApiLangCode();

        this.timeZoneMapping = data.reduce((obj, item) => {
          obj[item.id] = item;

          return obj;
        }, {});
      });
    },
    handlerPrivacyPopup(show) {
      this.visible.privacyPopup = show;

      if (show) {
        this.lockScroll.lock();
      } else {
        this.lockScroll.unlock();
      }
    },
  },
  created() {
    this.fetchNationality();
    this.fetchTimezoneData();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.info-wrap {
  @include flex();

  .t {
    text-align: right;
    margin-right: 10px;
    @include padding(0 10px 0 0);
    position: relative;

    &::after {
      content: "";
      @include position(tr, 50%, 0px);
      transform: translateY(-50%);
      width: 1px;
      height: 15px;
      display: inline-block;
      background-color: $c-black;
    }
  }

  &__detail {
    @include flex(center, center);
  }
}

.btn-wrap {
  @include flex(flex-end, center);
}

.reload {
  &__icon {
    width: 15px;
    margin-left: 10px;
  }
}

.privacy-btn {
  padding-top: 50px;
  text-align: center;
}

.inline-block {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .info-wrap {
    &--email {
      @include flex(flex-start, flex-start);
    }

    &__detail {
      @include flex(flex-start, flex-start, column);

      .c-verify-status {
        margin-left: 0;
      }

      &__email {
        margin-bottom: 10px;
      }
    }
  }
}

</style>
