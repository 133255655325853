<template>
  <div class="member">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh" />
    <MemberDataInfo
      :Classification="Classification"
      :memberProfile="memberProfile"
      :isTaiwanId="isTaiwanId"
      :getThisMemberProfileFn="getThisMemberProfile"
    />
  </div>
</template>

<script>
import '@/assets/scss/news.scss';
import BannerKv from '@/components/BannerKv.vue';
import MemberDataInfo from '@/components/member/MemberDataInfo.vue';
// eslint-disable-next-line no-unused-vars
import { isNationalIdentificationNumberValid } from 'taiwan-id-validator';
import { getMemberProfileV2 } from '@/lib/cakeHttp';
import { getUrlLangCode } from '@/utils/getLangCode';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MemberData',
  components: {
    BannerKv,
    MemberDataInfo
  },
  data() {
    return {
      isTaiwanId: false,
      BannerKvData: {
        titleEn: 'WELCOME',
        // titleZh: `${this.$cookies.get('name')} ${this.$t('helloGuest')}`,
        titleZh: this.$t('helloGuest', [this.$cookies.get('name')])
      },
      Classification: [
        {
          path: `/${getUrlLangCode()}/memberhome`,
          linkName: this.$t('myregistration')
        },
        {
          path: `/${getUrlLangCode()}/memberdata`,
          linkName: this.$t('myInfo')
        }
      ],
      isGetMemberProfileSuccess: false
    };
  },
  computed: {
    ...mapState(['memberInfo']),
    memberProfile() {
      return this.isGetMemberProfileSuccess ? this.memberInfo : null;
    }
  },
  methods: {
    ...mapMutations(['toggleShowMsg', 'setMsgInfo', 'logIn']),
    getThisMemberProfile() {
      this.showAppointmentData = [];
      if (this.$cookies.get('api_token')) {
        getMemberProfileV2(
          (response) => {
            this.logIn({
              member: response
            });
            this.isGetMemberProfileSuccess = true;
            if (isNationalIdentificationNumberValid(this.memberProfile.account)) {
              this.isTaiwanId = true;
            }
          },
          (response) => {
            this.$customSWAL({ icon: 'error', title: response });
          }
        );
      } else {
        this.toggleShowMsg(true);
        this.setMsgInfo({
          succeed: false,
          desc: this.$t('pleaseLogin'),
          callback: () => {
            this.$router.push({ name: 'Login' });
          },
          triggerCallbackOnOverlay: true,
          triggerCallbackOnConfirmBtn: true,
        });
      }
    }
  },
  mounted() {
    this.getThisMemberProfile();
  }
};
</script>
