<template>
  <div class="c-modify-password">
    <div class="c-modify-password__content">
      <div class="warning-wrap">
        <!-- <div class="warning">
          <img class="warning__icon" src="@/assets/icons/confirm.svg" />
        </div> -->

        <DecotTitle :title="$t('changePassword')" />

        <re-easy-form
          v-model="easyForm"
          :rules="rules"
          :labelConfig="labelConfig"
          ref="reEasyForm"
        />
      </div>

      <div class="btn-wrap">
        <re-button round="round" type="border" @click="submitEasyForm" :style="{ width: '120px' }">
          {{ $t("confirm") }}
        </re-button>
      </div>
    </div>
  </div>
</template>
<script>
import ReButton from '@/components/form/ReButton.vue';
import { modifyPasswordRule } from '@/config/easyFormRule';
import { easyFormLabelConfig } from '@/config/easyFormLabel';
import ReEasyForm from '@/components/form/ReEasyForm.vue';
import DecotTitle from '@/components/global/DecoTitle.vue';
import { changePassword } from '@/lib/cakeHttp';

export default {
  name: 'ModifyPassword',
  components: {
    ReButton,
    ReEasyForm,
    DecotTitle
  },
  data() {
    return {
      easyForm: [
        // {
        //   label: this.$t('oldPassword'),
        //   prop: 'originPassword',
        //   value: '',
        //   comp: 'input',
        //   type: 'password',
        //   placeholder: this.$t('passwordPlaceholder'),
        //   layoutWidth: '100'
        // },
        {
          label: this.$t('newPassword'),
          prop: 'newPassword',
          value: '',
          comp: 'input',
          type: 'password',
          placeholder: this.$t('passwordPlaceholder'),
          layoutWidth: '100'
        },
        {
          label: this.$t('comfirmNewPassword'),
          prop: 'confirmNewPassword',
          value: '',
          comp: 'input',
          type: 'password',
          placeholder: this.$t('refillPasswordPlaceholder'),
          layoutWidth: '100'
        }
      ],
      modifyPasswordRule,
      labelConfig: easyFormLabelConfig
    };
  },
  computed: {
    filterData() {
      return this.easyForm.reduce((obj, item) => {
        obj[item.prop] = item.value;

        return obj;
      }, {});
    },
    rules() {
      return modifyPasswordRule(this.filterData);
    }
  },
  methods: {
    resetFields() {
      this.easyForm = this.easyForm.map((item) => {
        item.value = '';

        return item;
      });
    },
    async submitEasyForm() {
      const checkForm = await this.$refs.reEasyForm.validateForm();

      if (checkForm) {
        changePassword(
          {
            old_password: this.filterData.originPassword,
            password: this.filterData.newPassword
          },
          () => {
            this.resetFields();
            this.$emit('beforeClose', 'modifyPassword', { action: 'succeed', actionType: 'msg' });
          },
          (err) => {
            this.$emit('beforeClose', '', { action: 'fail', actionType: 'msg', err });
          }
        );
      }
    },
    toLoginPage() {
      this.$router.push({
        name: 'Login'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.c-modify-password {
  @include padding(30px);
  background-color: #f8f8f8;
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);
}

.warning-wrap {
  @include flex(center, center, column);
}

.warning {
  @include padding(0 0 20px);

  &__icon {
    width: 50px;
  }
}

.desc {
  &__text {
    @include font-style($c-black, 16, 400, 1px, 24px);
    text-align: center;
  }
}
</style>
